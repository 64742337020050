// Social icon - Mail
ul.socialIcons a.email:before {
  content: '\f003';
}


// Header - Items order
.pageHeader .headerContent .mainCallToAction {
  margin-left: auto;
  .socialIcons { order: 1; }
  .header-search { order: 2; }
  .mainCallToActionButtons { order: 3; }
  .menuMainAlt { order: 4; }
}


// Tablet stuff
@media (max-width: $nav-breakpoint) {
  // Header - Layout
  .pageHeader .headerContent {
    padding-top: 0;
    padding-bottom: $spacer *  1.5;
    border-bottom: 3px solid $_blue-sky;
  }
  // Nav Search - Removbe duplicate
  .menuMain .header-search:first-of-type {
    display: none;
  }
  // Nav social icons - Layout
  .menuMain .mainCallToAction .socialIcons {
    justify-content: center;
  }
}


//  Mobile stuff - Header layout
@media (max-width: $cta-btn-breakpoint) {
  .pageHeader .headerContent {
    padding-top: $header-content-padding-top;
    padding-bottom: $header-content-padding-bottom;
  }
}


// Header - CTA
.mainCallToAction .cta-button:not(.donate) {
  color: $_blue-sky;
  border-color: $_blue-sky;
  &:hover {
    border-color: $_blue-sky;
    background-color: $_blue-sky;
    color: $_blue-navy;
  }
}

// Header - CTA Donate
.mainCallToAction .cta-button.donate:hover {
  background-color: $white;
  border-color: $white;
}

// Header - Social icons
.mainCallToAction ul.socialIcons a:hover {
  background-color: $white;
}


// Accessibility widget - Layout
.at-actions-container {
  width: 100%;
  max-width: $container-max-width;
  margin: 0 auto;
  justify-content: flex-end;
  background-color: $_blue-navy !important;
  padding: 0 $site-bleed;
  .at-action-group {
    border-left: none !important;
  }
  .at-button--contrast-normal:before {
    border: 3px solid $_turquoise !important;
  }
}


// Navigation - Layout
@media (min-width: #{$nav-breakpoint + 1px}) {
  .menuMain ul.topLevel {
    padding-bottom: $spacer;
  }
}


// Burger button
.menuMainAlt.active,
.menuMainAlt:hover {
  background-color: $burger-btn-background-colour;
}


// Header search
.header-search button {
  font-size: 0;
}


// Carousel - Underline
.carousel {
  box-shadow: $card-box-shadow;
}


// Carousel - Disable transition
.carousel * {
  transition: 0ms all !important;
}


// Carousel - Heading
.carouselSlideHeading {
  font-weight: $font-weight-black;
}


// Carousel - Button
.carouselReadMore {
  border-color: $carousel-summary-colour;
}


// Carousel adjacent layout - Banner layout
.carouselSlideTitle img {
  height: 100%;
  object-fit: cover;
}


// Carousel control - Desktop Layout
@media (min-width: #{$carousel-breakpoint + 1px}) {
  .carouselControls {
    justify-content: space-between;
    align-items: center;
    width: $carousel-details-max-width;
    margin-left: auto;
    a {
      background-color: rgba($black, 0.1);
      border-radius: $carousel-controls-button-size;
    }
  }
}


// Carousel control - Arrows asset
.carouselControls a {
  position: relative;
  font-size: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover { background-color: $white; }

  &:before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  &.carouselControlPrev:before { background-image: url($assets-path + "arrow-left.svg"); }
  &.carouselControlNext:before { background-image: url($assets-path + "arrow-right.svg"); }
}


// Home Intro -  Layout
.homeIntro {
  display: flex;
  flex-direction: row;
  padding-left: calc(#{$site-bleed} - #{$card-gap-width / 2});
  padding-right: calc(#{$site-bleed} - #{$card-gap-width / 2});
  // Home Intro -  Responsive
  @media (max-width: map-get($breakpoints, md)) {
    flex-direction: column;
  }
}


// Home Intro - Inner layout
.homeIntroBox {
  @include card-prefab-flex-column(2);
  position: relative;
  background-color: $white;
  box-shadow: $card-box-shadow;
  padding: $spacer * 2;
  margin: #{$card-gap-width / 2} !important;
  overflow: hidden;
  transition: $transition-duration all $transition-curve;
  &:hover {
    box-shadow: $card-hover-box-shadow;
    transition: $transition-duration all $transition-curve;
  }

  // Home Intro - Text
  &.homeIntroText {
    h2 {
      font-family: $headings-font-family;
      font-weight: $font-weight-black;
    }
    p {
      font-size: 1.075em;
      a {
        font-weight: $font-weight-black;
        text-decoration: none;
        border-bottom: 3px solid $_blue-sky;
        &:hover {
          color: $_blue-sky;
        }
      }
    }
  }
  // Home Intro - Video
  &.homeIntroVideo {
    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
    // Home Intro - Video mobile
    @media (max-width: map-get($breakpoints, md)) {
      height: 0;
      padding: 0 0 54.25%  0;
    }
  }
}


// Home Intro - Background
.homeIntroWrapper:after {
  content: '';
  position: absolute;
  width: 100vw;
  height: 500%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  // background-image: url($assets-path + "background_01.svg");
  background-image: url($assets-path + "background_01.png");
  background-size: 100% auto;
  background-repeat: repeat-y;
  background-position: top center;
  z-index: -1;
}


// Home Feed - Background
.homeFeedsWrapper {
  // background-image: url($assets-path + "background_02.svg");
  background-image: url($assets-path + "background_02.png");
  background-size: 100% auto;
  background-repeat: repeat-y;
  background-position: top center;
}


// Homepage stats - Heading underline
.homefeaturecategory-homeboximpactstats .homeImpactWrapper > h2:after {
  content: '';
  display: block;
  width: 125px;
  height: 40px;
  background-image: url($assets-path + "white-underline.svg");
  background-position: center;
  background-size: contain;
  margin: 0.5em auto;
  background-repeat: no-repeat;
}


// Quick Giving Panel - Donation selected
.quickGivingPanel .donationAmount.donationSelected {
  box-shadow: inset 0px 0px 0px 3px $_blue-navy;
}



// Footer - Layout
.pageFooterWrapper {
  border-bottom: 8px solid $_blue-sky;
}


// Newsletter - Layout
@media (min-width: map-get($breakpoints, md) + 1px) {
  .footerBox[class*="ewsletter"] {
    padding-right:  $spacer * 2;
  }
}


// Footer - Legal info
.footerBox[class*='info'] {
  text-align: center;
  padding-top: $spacer * 2;
}


// Footer - Logos
.footerBox[class*='ogos'] {
  width: 100%;
  max-width: $container-max-width;
  margin: 0 auto;
  padding: $spacer*2 $site-bleed;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  img {
    margin: 15px;
    width: 100%;
    height: 100%;
    max-width: 160px;
    max-height: 160px;
  }
}


// Listing page - Background
.pageWrapper:not(.searchListingPage) {
  // background-image: url($assets-path + "background_03.svg");
  background-image: url($assets-path + "background_03.png");
  background-size: 100% auto;
  background-repeat: repeat-y;
  background-position: top center;
  padding-bottom: $spacer * 3;
}

body[class*='forms-'] .pageWrapper {
  padding: 0 !important;
}

body[class*='forms-'] .customForm {
  // background-image: url($assets-path + "background_04.svg");
  background-image: url($assets-path + "background_04.png");
  background-size: 100% auto;
  background-repeat: repeat-y;
  background-position: top center;
  padding-top: $spacer * 3;
  padding-bottom: $spacer * 3;
  margin-bottom: 0;
  .postContent {
    background-color: $white;
    padding-top: $spacer * 2;
    padding-bottom: $spacer * 2;
  }
}

// Donation form - Background
#stripe-root {
  // background-image: url($assets-path + "background_04.svg");
  background-image: url($assets-path + "background_04.png");
  background-size: 100% auto;
  background-repeat: repeat-y;
  background-position: top center;
  padding-bottom: $spacer * 3;
}


// Inner page - Layout
.contentBlockWrapper, .contentBlock {
  margin-bottom: 0;
  padding-bottom: $spacer;
}


// Content - White background
.post .contentBlockWrapper,
.post .headerText {
  background-color: $white;
}


// Post - Header Text - Layout
.post .headerText {
  max-width: $post-content-max-width;
  margin: $spacer*3 auto 0;
  padding: $spacer 0 #{$spacer * 0.5};
  @media (max-width: $post-content-max-width) {
    margin: 0 auto;
    padding: $spacer 0 0;
  }
}


// Post content - Layout
.post .contentBlockWrapper:not(.yourDonationWrapper):not(.registerWrapper) {
  padding-top: $gap-width;
  padding-bottom: $gap-width;
  border-bottom: 8px solid $_blue-navy;
}


// Donation progress - White background
.progress-meter {
  margin-top: $gap-width;
  background-color: $white;
}


// Content - White background
.paymentPage .formWrapper {
  background-color: $white;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 8px solid $_blue-navy;
}


// Listing - Header Text - Layout
.listing .headerText {
  background-color: $white;
  max-width: calc(#{$listing-content-max-width} - #{$site-bleed * 2});
  margin: $spacer*2 auto;
  padding: $spacer;
  @media (max-width: calc(#{$listing-content-max-width} - #{$site-bleed * 2})) {
    margin: 0 auto $spacer;
  }
}


// Page Title - Layout
h1.title {
  // padding-bottom: $headings-margin-bottom;
}


// Carousel slide image ratio
.carouselSlide img {
  max-height: 496px;
  object-fit: cover;
  object-position: center center;
  @media (max-width: 1240px) {
    max-height: 40vw;
  }
}


// Inner content - Heading colour
.postContent h2,
.postContent h2 span,
.postContent h3,
.postContent h3 span,
.postContent h4,
.postContent h4 span,
.postContent h5,
.postContent h5 span,
.postContent h6,
.postContent h6 span {
  color: $_blue-reg !important;
}

// Inner content - Links
.postContent a:not([class*='button']),
.listingIntro a:not([class*='button']),  {
  border-bottom: 2px solid $_blue-sky !important;
  font-weight: $font-weight-black !important;
  color: $body-colour !important;
  span { color: $body-colour !important; }
  &:hover {
    text-decoration: none !important;
    color: $_blue-sky !important;
    span { color: $_blue-sky !important; }
  }
}

// QA
body:not(.userLoggedIn) {
  .menuAdminLogOut {
    display: none;
  }
}